<template>
  <ContentFooter :dir-page="true"></ContentFooter>
</template>
<script>
import ContentFooter from "@/pages/Layout/ContentFooter";
export default {
  name: 'Directory',
  components: {ContentFooter},
  created() {
    this.$store.state.showFooter = false;
  },
  destroyed() {
    this.$store.state.showFooter = true;

  }
}
</script>
